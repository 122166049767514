<template>
  <b-card style="width: 100%" class="play-back-card">
    <BottomArrow
      :class="
        islarge ? 'large-card bottom-arrow-expand' : 'bottom-arrow-expand'
      "
      v-b-tooltip.hover.v-primary="{
        title: isOnlyPlayBack
          ? $t('Playback.Expanded')
          : $t('Playback.Collapsed'),
        customClass: 'tooltip-card'
      }"
      @click="
        (e) => {
          startPlayback(false);
          islarge = !islarge;
        }
      "
    />
    <div class="slider-row">
      <div
        :class="UnitInfo && UnitInfo.isLive ? 'col-10-space' : 'col-12-space'"
      >
        <vue-slider
          @change="handleSliderSlide"
          :duration="0"
          :tooltip-formatter="
            (v) => {
              return new Date(v * 1000).toLocaleString();
            }
          "
          v-model="value"
          :min="minTime"
          :max="maxTime"
        ></vue-slider>
      </div>

      <div
        class="col-2-space"
        v-if="UnitInfo && UnitInfo.isLive"
        v-b-tooltip.hover.v-primary="{
          title: isLiveUnit ? $t('Playback.Live') : $t('Playback.Recorded'),
          customClass: 'tooltip-card'
        }"
      >
        <b-form-checkbox
          v-model="isLiveUnit"
          switch
          @change="liveToggle()"
          variant="danger"
        />
      </div>
    </div>
    <div class="play-back-control">
      <div class="play-back-left">
        <div class="ctr-1" v-if="!isPaused">
          <PauseIcon
            class="curserPointer"
            @click="
              (e) => {
                isPaused = !isPaused;
                pauseTrack();
              }
            "
          />
        </div>

        <div class="ctr-1" v-if="isPaused">
          <PlayIcon
            class="curserPointer"
            @click="
              (e) => {
                isPaused = !isPaused;
                playTrack();
              }
            "
          />
        </div>
        <div class="ctr-1">
          <StopIcon
            class="curserPointer"
            @click="
              (e) => {
                isPaused = true;

                stopTrack();
              }
            "
          />
        </div>
        <div class="date-ctr">
          {{ DateTimeConvert(this.value * 1000, constants.DATE_TIME_FORMAT) }}
        </div>
      </div>
      <div class="avg-ctr">
        <AvgMeterIcon />
        {{ playTrackData.speed }}
        km/h
      </div>
      <div class="loca-ctr">
        <LocationIcon /> {{ playTrackData.distance.toFixed(1) }} km
      </div>
      <div class="get-Speed">
        <v-select
          v-model="playSpeed"
          v-b-tooltip.hover.v-primary.bottom="{
            title: $t('Playback.SpeedPlayBack'),
            customClass: 'tooltip-card'
          }"
          @input="changeSpeed"
          :options="getSpeed()"
          :reduce="(option) => option.value"
          label="label"
          :clearable="false"
          :searchable="false"
          :menu-props="{
            positionY: 'top',
            offsetY: true
          }"
          class="curserPointer no-border no-underline"
        >
          <template #selected-option="{ label }">
            <PlaySpeedIcon />
            <span class="ml-50 text-body">{{ label }}</span>
          </template>
        </v-select>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BTooltip,
  BButton,
  BCol,
  BRow,
  BFormCheckbox
} from "bootstrap-vue";
import PlayIcon from "@/assets/images/unit-icon/play.svg";
import StopIcon from "@/assets/images/unit-icon/stop.svg";
import AvgMeterIcon from "@/assets/images/unit-icon/km-icon.svg";
import LocationIcon from "@/assets/images/unit-icon/location-icon.svg";
import PauseIcon from "@/assets/images/unit-icon/pause.svg";
import PlaySpeedIcon from "@/assets/images/unit-icon/play-speed.svg";
import BottomArrow from "@/assets/images/unit-icon/bottom-arrow.svg";
import store from "@/store";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import vSelect from "vue-select";
import constants from "@/utils/constants";

export default {
  components: {
    BCard,
    VBTooltip,
    BTooltip,
    BButton,
    BCol,
    BRow,
    BFormCheckbox,
    VueSlider,
    PlayIcon,
    StopIcon,
    PlaySpeedIcon,
    AvgMeterIcon,
    LocationIcon,
    PauseIcon,
    vSelect,
    BottomArrow
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      minTime: 0,
      maxTime: 0,
      value: 0,
      playSpeed: 0,
      isPaused: true,
      playTrackInterval: "",
      slideChange: "",

      isLiveUnit: true,
      playBackSetTimeOut: "",
      islarge: true,
      startPoint: {},
      playTrackData: {
        date: 0,
        speed: 0,
        distance: 0
      },
      index: 0
    };
  },
  props: ["playback", "startPlayback", "isOnlyPlayBack", "UnitInfo"],
  watch: {
    playback: {
      handler(newVal, oldVal) {
        if (!oldVal && newVal) {
          this.initPlayback();
        }
      }
    },
    isLiveUnit(val) {
      // this.playback.events.fire("onLiveToggle", val);
      store.commit("mapLoader/SET_IS_LIVE_UNIT", val);
    }
  },
  mounted() {
    store.commit("mapLoader/SET_IS_LIVE_UNIT", this.isLiveUnit);
    if (this.playback) {
      this.maxTime = this.playback.getEndTime();
      this.minTime = this.playback.getStartTime();
      this.value = this.maxTime;
      this.initPlayback();
      // this.playback.pause();
      this.playback.moveToEndWithOutStop();
      // this.value = this.playback.getEndTime();
    }
  },
  beforeDestroy() {
    this.playback.events.off("tick");
    this.playback.events.off("timeUpdated");
    this.playback.events.off("stop");
  },
  computed: {},
  methods: {
    initPlayback() {
      this.maxTime = this.playback.getEndTime();
      this.minTime = this.playback.getStartTime();

      this.playback.events.on("tick", (data) => {
        if (data.time >= this.maxTime) {
          this.maxTime = data.time;
        }
        this.value = data.time;
        if (this.isLive && this.value === this.maxTime) {
          this.isPaused = false;
        }
        if (!this.isLiveUnit && this.maxTime == this.value) {
          this.isPaused = true;
          this.playback.pause();
          this.playback.recenterMap();
        }
        this.playTrackData = {
          speed: data?.message?.speed || 0,
          distance: data?.message?.distance || 0
        };
      });
      this.playback.events.on("timeUpdated", (data) => {
        data.endTime = data.endTime || 1;
        data.startTime = data.startTime || 1;
        this.maxTime = Math.max(data.endTime, this.value) || 1;
        this.minTime = data.startTime || 1;
        this.value = Math.min(data.endTime, this.value);
      });
      this.playback.events.on("stop", (data) => {
        this.value = this.playback.getEndTime();
        this.isPaused = true;
      });
    },
    changeSpeed(value) {
      this.playback.setSpeed(value);
    },

    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },

    getSpeed() {
      return Array.from({ length: 9 }, (_, index) => ({
        label: index == 0 ? this.$t(`Playback.Auto`) : index,
        value: index
      }));
    },
    stopTrack() {
      this.playback.stop();
      this.playback.recenterMap();
      this.isLiveUnit = false;
      setTimeout(() => {
        // this.value = this.minTime;
      }, 50);
    },
    pauseTrack() {
      // console.log(this.playback);
      this.playback.pause();
      this.playback.recenterMap();
      this.isLiveUnit = false;
      //  clearTimeout(this.playBackSetTimeOut);
    },
    playTrack() {
      this.playback.play();
      this.playback.recenterMap();
      this.startPlayback(true);
      this.isLiveUnit = false;
    },

    playBackStart(selectedTripHistoryValue) {},
    findMaxNearest(arrayOfObjects) {},
    handleSliderSlide(e) {
      this.playback.seek(e);
      if (this.UnitInfo && this.UnitInfo.isLive) {
        this.isLiveUnit = e === this.maxTime ? true : false;
        store.commit("mapLoader/SET_IS_LIVE_UNIT", this.isLiveUnit);
        this.isPaused = true;

        //  this.playback.events.fire("onLiveToggle", this.isLiveUnit);
      }
      //  this.playback.events.fire("handleSliderSlideChange", {});
    },
    liveToggle() {
      if (this.isLiveUnit) {
        this.playback.moveToEnd();
        this.playback.recenterMap();
      }
    }
  },
  destroyed() {
    clearTimeout(this.playBackSetTimeOut);
  }
};
</script>

<style lang="scss">
.unit-info-main .vue-slider-dot-tooltip {
  display: none;
}
@import "@core/scss/vue/libs/vue-select.scss";
.unit-info-main {
  border-radius: 8px;
  background-color: var(--white);
  padding: 15px;
  min-height: 122px;
  box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
  display: flex;
  flex-wrap: wrap;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  .play-back-card {
    .v-select ul {
      margin-top: -300px !important;
      max-width: 80px;
      min-width: 80px;
    }
  }
}

.outer-info.playback-only .unit-info-main {
  padding: 0;
  .play-back-card {
    margin-bottom: 0;
  }
}
.dark-layout {
  .unit-info-card {
    background-color: var(--unit-bg-dark);
    .cust-card-unit {
      .unit-name-ev-block {
        .unit-name-ev-text {
          color: var(--light);
        }
      }
    }
    .shift-time {
      color: var(--light);
    }
  }
  .unit-info-main {
    background-color: var(--unit-bg-dark);
    .cust-card-unit {
      .unit-name-ev-block {
        .unit-name-ev-text {
          color: var(--light);
        }
      }
    }
    .shift-time {
      color: var(--light);
    }
  }
  .outer-info .close {
    background-color: var(--dark46) !important;
    color: var(--white);
  }
}

.dark-layout {
  .inner-span {
    .event-card {
      background-color: var(--dark46);
    }
  }
  .outer-span {
    .info-icon-overlay {
      background-color: var(--dark46);
    }
  }
}
.slider-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .col-10-space {
    width: calc(100% - 60px);
  }
  .col-12-space {
    width: 100%;
  }
  .col-2-space {
    width: 60px;
    padding-left: 15px;
  }
}
.play-back-control {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-left: -5px;
  margin-right: -5px;
  .play-back-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .ctr-1 {
    width: 40px;
  }
  .loca-ctr {
    font-size: 11px;
    margin-left: 7px;
    min-width: 115px;
  }
  .date-ctr {
    font-size: 13px;
    min-width: 135px;
  }
  .avg-ctr {
    font-size: 11px;
    margin-left: 7px;
    min-width: 115px;
  }
  .get-Speed {
    width: 78px;
    margin-left: 10px;
    .no-underline .v-select__selections {
      border: none !important;
      box-shadow: none !important;
    }
    .vs__dropdown-toggle {
      border: none !important;
      box-shadow: none !important;
    }
    .vs--single .vs__dropdown-toggle {
      padding-left: 0px;
    }
    .vs__selected {
      margin: 4px 0px 0px 2px;
    }
    .vs__actions {
      display: none;
    }
    .vs__selected-options {
      input {
        display: none;
      }
    }
  }
}
.bottom-arrow-expand {
  position: relative;
  right: 8px;
  bottom: 8px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}
.large-card.bottom-arrow-expand {
  transform: rotate(-180deg);
}
@media screen and (min-width: 1450px) {
  .play-back-left {
    .loca-ctr,
    .date-ctr,
    .avg-ctr {
      font-size: 14px;
    }
    .loca-ctr {
      min-width: 155px;
    }
    .date-ctr {
      min-width: 175px;
    }
    .avg-ctr {
      min-width: 155px;
    }
  }
}
@media screen and (max-width: 667px) {
  .outer-info {
    margin-top: 100px;
    .unit-info-main {
      padding: 0 !important;
      .play-back-card {
        .v-select ul {
          margin-top: -290px !important;
          max-width: 50px;
          min-width: 50px;
          .vs__dropdown-option,
          .vs__no-options {
            padding: 4px 7px;
          }
        }
      }
    }
    &.playback-only {
      // height: 0 !important;
      .unit-info-main {
        position: fixed;
        bottom: 0;
        width: 100%;
      }
    }
    &:after {
      background: #fff;
    }
  }
  // .playback-only {
  //   &.outer-info {
  //     .unit-info-main {
  //       .play-back-card {
  //         .v-select ul {
  //           margin-top: -290px !important;
  //         }
  //       }
  //     }
  //   }
  // }
  .outer-info {
    .unit-info-main {
      .play-back-card {
        .v-select ul {
          margin-top: -120px !important;
          max-height: 90px;
        }
      }
    }
  }
  .dark-layout {
    .outer-info {
      &:after {
        background: var(--unit-bg-dark);
      }
    }
  }
}
</style>
